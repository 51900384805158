@charset "utf-8";

@import "variables";



#loading{
	span{
		display: block;
		position: absolute;
		top: 70%;
		left: 50%;
		width: 35%;
		max-width: 150px;
		opacity: 0;
		transform: translate(-50%, -50%);
		@include easeOutExpo_2s;
		svg{
			path,
			polyline,
			{
				fill: #fff;
				transition: all .5s;
			}
		}
	}
	span.on{
		top: 50%;
		opacity: 1;
	}
}
#loading:before{
	content: '';
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 0;
	height: 0;
	background-color: #3FAC48;
	border-radius: 50%;
	transform: translate(-50%, -50%);
	@include easeOutExpo_2s;
}
#loading.on:before{
	width: 200vw;
	height: 200vw;
}
#loading.fill{
	svg{
		path.green{
			fill: #3FAC48;
		}
		path.gray,
		polyline.gray{
			fill: #80817E;
		}
		path.yellow,
		polyline.yellow{
			fill: #F5C300;
		}
	}
}
#loading.fill:before{
	background-color: #fff;
}

#main-view{
	overflow: hidden;
	position: relative;
}
#main-view:before{
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	height: 100%;
	background: url(../img/common/bg_green.png);
	transform: skew(-25deg);
	transform-origin: left top;
	@include easeOutExpo_2s;
}
#main-view.on:before{
	width: 70%;
	transform: skew(-25deg);
}
#main-view:after{
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.2);
	transition: all 1s;
}
#slides {
	overflow: hidden;
	position: relative;
	span{
		overflow: hidden;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		i{
			display: block;
			width: 100%;
			height: 100%;
			transition: all 8s linear;
		}
	}
	span:first-of-type{
		i{
			background: url(../img/top/slides/img01.jpg) no-repeat 60% center;
			background-size: cover;
		}
	}
	span:nth-of-type(2){
		i{
			background: url(../img/top/slides/img02.jpg) no-repeat 70% center;
			background-size: cover;
		}
	}
	span:nth-of-type(3){
		i{
			background: url(../img/top/slides/img03.jpg) no-repeat 70% center;
			background-size: cover;
		}
	}
	span:nth-of-type(4){
		i{
			background: url(../img/top/slides/img04.jpg) no-repeat 70% center;
			background-size: cover;
		}
	}
	span:nth-of-type(5){
		i{
			background: url(../img/top/slides/img05.jpg) no-repeat 60% center;
			background-size: cover;
		}
	}
	span:nth-of-type(6){
		i{
			background: url(../img/top/slides/img06.jpg) no-repeat 70% center;
			background-size: cover;
		}
	}
	span:nth-child(odd) {
		i {
			transform: scale(1.2);
		}
	}
	span:nth-child(odd).show {
		z-index: 1;
		position: relative;
		i {
			transform: scale(1);
		}
	}
	span:nth-child(even) {
		i {
			transform: scale(1);
		}
	}
	span:nth-child(even).show {
		z-index: 1;
		position: relative;
		i {
			transform: scale(1.2);
		}
	}
}
#indicator {
	text-align: center;
	margin: 15px 0 0;
	em {
		cursor: pointer;
		display: inline-block;
		width: 13px;
		height: 13px;
		margin: 0 10px;
		background-color: #ada29c;
		border-radius: 50%;
	}
	em.active {
		background-color: #e47839;
	}
}

#catch{
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 2;
	color: #fff;
	width: 100%;
	max-width: 1000px;
	transform: translate(-50%, -50%);
	// text-shadow: 1px 1px 0 rgba(0,0,0,0.2);
	.inner{
		padding: 0 15px;
	}
	strong{
		position: relative;
		display: block;
		font-size: 30px;
		line-height: 1.4;
		font-family: $fontRobot;
		font-weight: 700;
		letter-spacing: 1px;
		padding: 0 0 5%;
		margin: 0 0 5%;
		b{
			display: block;
			position: relative;
			left: 10%;
			opacity: 0;
			transition: all 1s;
		}
		b.on{
			left: 0;
			opacity: 1;
		}
	}
	strong:before{
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100px;
		height: 2px;
		background-color: #fff;
	}
	p{
		font-size: 16px;
		font-family: $fontJa;
		// font-weight: 400;
		font-weight: 500;
		letter-spacing: 2px;
		opacity: 0;
		transition: all 2s;
	}
	p.on{
		opacity: 1;
	}
}

section{
	overflow: hidden;
	padding: 8% 0;
	.sec-head{
		position: relative;
		text-align: center;
		padding: 0 0 3%;
		h2{
			color: #000;
			font-size: 20px;
			line-height: 1.6;
			font-family: $fontJa;
			font-weight: 800;
			span{
				display: inline-block;
			}
			b{
				position: relative;
				top: 1px;
				display: inline-block;
				font-size: 1.2em;
				letter-spacing: 1px;
				margin: 0 5px;
				font-family: $fontRobot;
			}
		}
		p{
			margin: 10px 0;
		}
	}
}

.sec01,
.sec02{
	.sec-head{
		border-bottom: 1px solid #9d9d9d;
	}
	.sec-head:before{
		content: '';
		display: block;
		position: absolute;
		bottom: -1px;
		left: 50%;
		width: 20px;
		height: 20px;
		border-top: 1px solid #9d9d9d;
		border-right: 1px solid #9d9d9d;
		background-color: #fff;
		transform: translate(-50%, 50%) rotate(135deg);
	}
}

.sec01{
	padding: 5% 0 0;
	.sec-body{
		position: relative;
		padding: 6% 0 0;
	}
	.sec-body:before{
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		width: 100%;
		height: 100%;
		background-color: transparent;
	}
}
#anime{
	position: relative;
	margin: 18% 0 0;
	border-bottom: 2px solid #35A138;
	.anime-bg{
		position: relative;
		top: 1px;
		line-height: 0;
		padding: 0 10px;
		max-width: 1200px;
		margin: auto;
		transform: scale(1.5);
		transform-origin: center bottom;
	}	
}

#canvas-container{
	position: absolute;
	bottom: -10px;
	left: 0;
	canvas{
		width: 100vw;
		height: 20vw;
	}
}
#slick-person{
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	li{
		span{
			display: block;
			width: 100%;
			height: 0;
			padding-top: 100%;

			max-width: 225px; // twice - add

			-webkit-backface-visibility:hidden;
			backface-visibility:hidden;
			overflow:hidden;
		}
	}
	li.person01 span{
		background: url(../img/top/sec01/anime/p01_1.png) no-repeat center bottom;
		background-size: 100% auto;
		animation: changeImg01 .5s linear infinite;
	}
	li.person02 span{
		background: url(../img/top/sec01/anime/p02_1.png) no-repeat center bottom;
		background-size: 100% auto;
		animation: changeImg02 .7s linear infinite;
	}
	li.person03 span{
		background: url(../img/top/sec01/anime/p03_1.png) no-repeat center bottom;
		background-size: 100% auto;
		animation: changeImg03 .5s linear infinite;
	}
	li.person04 span{
		background: url(../img/top/sec01/anime/p04_1.png) no-repeat center bottom;
		background-size: 100% auto;
		animation: changeImg04 .7s linear infinite;
	}
	li.person05 span{
		background: url(../img/top/sec01/anime/p05_1.png) no-repeat center bottom;
		background-size: 100% auto;
		animation: changeImg05 .7s linear infinite;
	}
	li.person06 span{
		background: url(../img/top/sec01/anime/p06_1.png) no-repeat center bottom;
		background-size: 100% auto;
		animation: changeImg06 .7s linear infinite;
	}
	li.person07 span{
		background: url(../img/top/sec01/anime/p07_1.png) no-repeat center bottom;
		background-size: 100% auto;
		animation: changeImg07 .7s linear infinite;
	}
}

#pi{
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	padding: 0 10px;
	box-sizing: border-box;
	transform: translateY(-150%);
	span{
		display: block;
		text-align: right;
		max-width: 1300px;
		margin: auto;
		img{
			width: 50px;
			animation: scale2x 2.1s linear infinite;
		}
	}
}


.sec02{
	padding-top: 0;
	.sec-body{
		img{
			max-width: 1360px; // twice - add
			margin: auto; // twice - add
		}
	}
}
.sec02-2{
	padding: 0 0 1%;
}

.sec03{
	background: url(../svg/dot.svg);
	.inner{
		max-width: 1200px;
		padding: 0 15px;
	}
}

#cont-list{
	letter-spacing: -.4em;
	.cont{
		letter-spacing: normal;
		overflow: hidden;
		position: relative;
		margin: 15px 0 0;
		box-sizing: border-box;
		// box-shadow: 5px 5px 0 rgba(0,0,0,0.2); // twice - add
		.cont-body{
			position: relative;
			a{
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 3;
				width: 100%;
				height: 100%;
			}
		}
		.cont-img{
			line-height: 0;
			img{
				transform: scale(1);
				transition: all .3s;
			}
		}
		.cont-title{
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			z-index: 2;
			opacity: 0;
			transform: translateY(-50%);
			@include easeOutExpo_2s;
			h3{
				position: relative;
				color: #fff;
				font-size: 20px;
				font-family: $fontJa;
				font-weight: 600;
				letter-spacing: 2px;
				padding: 0 0 10px;
				margin: 0 0 10px;
			}
			h3:before{
				content: '';
				display: block;
				position: absolute;
				bottom: 0;
				left: 0;
				width: 10%;
				height: 2px;
				background-color: #fff;
			}
			small{
				display: block;
				font-size: 16px;
				font-family: $fontRobot;
				font-weight: 700;
				letter-spacing: 1px;
			}
		}	
	}
	.cont:hover{
		.cont-img{
			img{
				transform: scale(2);
			}
		}
	}
	.cont.green{
		// border: 2px solid #41A348;
		.cont-title{
			small{
				color: #EDD60B;
			}
		}
	}
	.cont.yellow{
		// border: 2px solid #F5C300;
		.cont-title{
			small{
				color: #369738;
			}
		}
	}
	.cont.green:before,
	.cont.yellow:before{
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		transform: skew(-25deg);
		transform-origin: left top;
		@include easeOutExpo_1s;
	}
	.cont.green:before{
		background: url(../img/common/bg_lightgreen.png);
	}
	.cont.yellow:before{
		background: url(../img/common/bg_yellow.png);
	}

/* operation */
	.cont.on{
		.cont-title{
			left: 5%;
			opacity: 1;
		}
	}
	.cont.on:before{
		left: 0;
		width: 50%;
		transform: skew(-25deg);
	}
}

#news{
	overflow: visible !important;
	background-color: #fff;
	// border: 2px solid #2E6B3F;
	
	.cont-head{
		position: relative;
		padding: 5px 10px;
		background-color: #2E6B3F;
		h3{
			color: #fff;
			font-size: 20px;
			font-family: $fontRobot;
			font-weight: 700;
			background: url(../img/top/sec03/ico_news.png) no-repeat left 8px;
			background-size: 40px auto;
			padding-left: 50px;
		}
		a{
			display: block;
			position: absolute;
			top: 0;
			right: 10px;
			color: #2E6B3F;
			font-family: $fontJa;
			font-weight: 500;
			background-color: #F7E921;
			border: 1px solid #F7E921;
			padding: 5px 10px;
			transform: translateY(-50%);
			transition: all .3s;
		}
		a:after{
			content: '';
			display: inline-block;
			width: 0;
			height: 0;
			border: 6px solid transparent;
			border-left: 8px solid #2E6B3F;
			position: relative;
			top: 1px;
			left: 5px;
		}
		a:hover{
			background-color: #fff;
			border: 1px solid #fff;
		}
	}
}
.posts{

}
.post{
	position: relative;
	transition: all .3s;
	.post-in{
		overflow: hidden;
		padding: 10px 0;
		margin: 0 15px;
		border-top: 1px dashed #9d9d9d;
		.post-date{
			float: left;
		}
		.post-title{
			margin: 0 0 0 100px;
		}
	}
	a.permalink{
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
.post:hover{
	background-color: #eee;
}
.post:first-of-type{
	.post-in{
		border: 0;
	}
}


@media screen and (min-width: 600px) {

	#main-view.on:before{
		width: 50%;
	}
	#catch{
		strong{
			font-size: 40px;
			// font-weight: 400;
			line-height: 1.3;
			padding: 0 0 3%;
			margin: 0 0 3%;
		}
		p{
			font-size: 18px;
		}
	}

	section{
		padding: 5% 0;
		.sec-head{
			h2{
				font-size: 26px;
			}
		}
		p.lead{
			font-size: 16px;
			text-align: center;
			span{
				display: inline-block;
			}
		}
	}
	.sec01{
		.sec-body{
			padding: 4% 0 0;
		}
	}

	#anime{
		margin: 8% 0 0;
		.anime-bg{
			transform: scale(1.1);
		}	
	}
	#canvas-container{
		canvas{
			height: 18vw;
		}
	}
	#pi{
		transform: translateY(-120%);
	}

	#cont-list{
		margin: 0 -1%;
		.cont{
			display: inline-block;
			vertical-align: top;
			width: 48%;
			margin: 1%;
			.cont-title{
				h3{
					font-size: 24px;
				}
			}
		}
	}


}


@media screen and (min-width: 800px) {
	#catch{
		top: 56%;
		strong{
			font-size: 50px;
		}
		p{
			font-size: 20px;
		}
	}

	section{
		.sec-head{
			h2{
				font-size: 32px;
				b{
					top: 2px;
				}
			}
		}
	}

	.sec02{
		.sec-body{
			background: url(../img/top/sec02/bg_pc_chart.png) repeat-x;
			background-size: auto 100%;
		}
	}

	#anime{
		.anime-bg{
			transform: scale(1);
		}	
	}
	#canvas-container{
		canvas{
			height: 16vw;
		}
	}
	#pi{
		transform: translateY(-110%);
		span{
			img{
				// animation: scale2x 2.05s linear infinite;
			}
		}
	}


	#cont-list{
		.cont{
			// box-shadow: 10px 10px 0 rgba(0,0,0,0.2); // twice - add
			.cont-title{
				h3{
					font-size: 28px;
				}
			}
		}
	}
	#news{
		.cont-head{
			a{
				right: 15px;
			}
		}
	}

}


@media screen and (min-width: 1000px) {
	#catch{
		strong{
			font-size: 60px;
		}
		p{
			font-size: 22px;
		}
	}

	section{
		.sec-head{
			h2{
				font-size: 38px;
				b{
					top: 3px;
				}
			}
		}
		p.lead{
			font-size: 18px;
			font-family: $fontJa;
			font-weight: 500;
			span{
				display: block;
			}
		}
	}

	#anime{
		margin: 5% 0 0;
		.anime-bg{
			// top: 2px;
		}
	}
	#canvas-container{
		canvas{
			height: 12vw;
		}
	}
	#pi{
		transform: translateY(-100%);
	}

	.post{
		.post-in{
			padding: 15px 0;
		}
	}

	#cont-list{
		.cont{
			.cont-title{
				h3{
					font-size: 32px;
				}
			}
		}
	}

}


@media screen and (min-width: 1400px) {
	#catch{
		strong{
			font-size: 70px;
		}
	}
}

@media screen and (min-width: 1600px) {
	#catch{
		strong{
			font-size: 80px;
		}
	}
}


@keyframes scale2x {
	0%{transform: scale(1);}
	40%{transform: scale(1);}
	41%{transform: scale(1.3);}
	60%{transform: scale(1.3);}
	61%{transform: scale(1);}
	100%{transform: scale(1);}
}


@keyframes changeImg01 {
	0%{
		background: url(../img/top/sec01/anime/p01_1.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	50%{
		background: url(../img/top/sec01/anime/p01_1.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	51%{
		background: url(../img/top/sec01/anime/p01_2.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	100%{
		background: url(../img/top/sec01/anime/p01_2.png) no-repeat center bottom;
		background-size: 100% auto;
	}
}

@keyframes changeImg02 {
	0%{
		background: url(../img/top/sec01/anime/p02_1.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	33%{
		background: url(../img/top/sec01/anime/p02_1.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	34%{
		background: url(../img/top/sec01/anime/p02_2.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	66%{
		background: url(../img/top/sec01/anime/p02_2.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	67%{
		background: url(../img/top/sec01/anime/p02_3.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	100%{
		background: url(../img/top/sec01/anime/p02_3.png) no-repeat center bottom;
		background-size: 100% auto;
	}
}

@keyframes changeImg03 {
	0%{
		background: url(../img/top/sec01/anime/p03_1.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	50%{
		background: url(../img/top/sec01/anime/p03_1.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	51%{
		background: url(../img/top/sec01/anime/p03_2.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	100%{
		background: url(../img/top/sec01/anime/p03_2.png) no-repeat center bottom;
		background-size: 100% auto;
	}
}

@keyframes changeImg04 {
	0%{
		background: url(../img/top/sec01/anime/p04_1.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	33%{
		background: url(../img/top/sec01/anime/p04_1.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	34%{
		background: url(../img/top/sec01/anime/p04_2.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	66%{
		background: url(../img/top/sec01/anime/p04_2.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	67%{
		background: url(../img/top/sec01/anime/p04_3.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	100%{
		background: url(../img/top/sec01/anime/p04_3.png) no-repeat center bottom;
		background-size: 100% auto;
	}
}

@keyframes changeImg05 {
	0%{
		background: url(../img/top/sec01/anime/p05_1.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	33%{
		background: url(../img/top/sec01/anime/p05_1.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	34%{
		background: url(../img/top/sec01/anime/p05_2.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	66%{
		background: url(../img/top/sec01/anime/p05_2.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	67%{
		background: url(../img/top/sec01/anime/p05_3.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	100%{
		background: url(../img/top/sec01/anime/p05_3.png) no-repeat center bottom;
		background-size: 100% auto;
	}
}

@keyframes changeImg06 {
	0%{
		background: url(../img/top/sec01/anime/p06_1.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	33%{
		background: url(../img/top/sec01/anime/p06_1.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	34%{
		background: url(../img/top/sec01/anime/p06_2.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	66%{
		background: url(../img/top/sec01/anime/p06_2.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	67%{
		background: url(../img/top/sec01/anime/p06_3.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	100%{
		background: url(../img/top/sec01/anime/p06_3.png) no-repeat center bottom;
		background-size: 100% auto;
	}
}

@keyframes changeImg07 {
	0%{
		background: url(../img/top/sec01/anime/p07_1.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	33%{
		background: url(../img/top/sec01/anime/p07_1.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	34%{
		background: url(../img/top/sec01/anime/p07_2.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	66%{
		background: url(../img/top/sec01/anime/p07_2.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	67%{
		background: url(../img/top/sec01/anime/p07_3.png) no-repeat center bottom;
		background-size: 100% auto;
	}
	100%{
		background: url(../img/top/sec01/anime/p07_3.png) no-repeat center bottom;
		background-size: 100% auto;
	}
}